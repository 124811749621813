import $ from 'jquery'
const $doc = $(document)

function searchFormReveal() {
  const form = $("#nav-main-search");
  console.log(form);
  form.slideDown();
  $(document).keydown(function() {
    $("#nav-main-search-input").focus();
  });
}

function searchFormHide(event) {
  const el = $(event.currentTarget);
  const form = $("#nav-main-search");
  form.slideUp();
}

export default function initSearchForm(){
  $doc.on('click', '.js-search', searchFormReveal )
  $doc.on('click', '.js-search-close', searchFormHide )
}
