import $ from 'jquery'

const $doc = $(document)
const $mobileNav = $('.js-mobile-nav')
const $hamburger = $('.js-hamburger')

function showMobileNav(event) {
  event.preventDefault()
  $mobileNav.toggleClass('is-active')
  $hamburger.toggleClass('is-active')
  if ( $mobileNav.hasClass('is-active')) {
    $hamburger.attr('aria-pressed', 'true')
  } else {
    $hamburger.removeAttr('aria-pressed')
  }
}

export default function initMobileNav(){
  $doc.on('click', '.js-hamburger', showMobileNav )
}
