import $ from 'jquery'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

// Building blocks animations
function blocksFadeIn(elem) {
  gsap.to(elem, {
    duration: 1,
    opacity: 1,
    delay: 0.5,
    ease: "power1.out",
    offset: "50%"
  })
}

function setupBlocksFadeIn(elem) {
  ScrollTrigger.create({
    trigger: elem,
    onEnter: function() {
      blocksFadeIn(elem)
    }
  })
}


// Slide Up blocks animations
function blocksSlideUp(elem) {
  gsap.fromTo(elem, {
      opacity: 0,
      y: 150
    },
    {
      duration: 1,
      opacity: 1,
      delay: 0,
      y: 0,
      ease: "power1.out",
      offset: "50%"
  })
}

function setupBlocksSlideUp(elem) {
  ScrollTrigger.create({
    trigger: elem,
    onEnter: function() {
      blocksSlideUp(elem)
    }
  })
}

// header slide down
function headerSlideDown() {
  gsap.to(".header", {
    duration: .7,
    transform: "translate(0, 0)",
    ease: "power1.out",
  })
}

// footer opacity, maybe add slide up animation
function footerSlideUp() {
  gsap.to(".footer", {
    duration: 1.5,
    opacity: "1",
    ease: "power1.out",
    offset: "50%"
  })
}

function setupFooterSlideUp() {
  ScrollTrigger.create({
    trigger: ".footer",
    onEnter: function() {
      footerSlideUp()
    }
  })
}

// parallax image
function parallaxImage(item) {
  gsap.to(item, {
    yPercent: 50,
    ease: "none",
    scrollTrigger: {
      start: "-116px top",
      trigger: item,
      scrub: true
    },
  });
}

function init() {
  headerSlideDown();
  gsap.registerPlugin(ScrollTrigger)
  gsap.utils.toArray(".building-block").forEach(setupBlocksFadeIn)
  gsap.utils.toArray(".single-image img").forEach(setupBlocksFadeIn)
  gsap.utils.toArray(".home-image-text__item").forEach(setupBlocksFadeIn)
  gsap.utils.toArray(".home-hero").forEach(setupBlocksFadeIn)
  gsap.utils.toArray(".home-title").forEach(setupBlocksFadeIn)
  gsap.utils.toArray(".home-cards").forEach(setupBlocksSlideUp)
  gsap.utils.toArray(".contact-page").forEach(setupBlocksFadeIn)
  gsap.utils.toArray(".single-image.small-height img").forEach(parallaxImage)
  gsap.utils.toArray(".article").forEach(setupBlocksFadeIn)
  setupFooterSlideUp();
}

export default function initGreenSock() {
  $(document).ready(init)
}
