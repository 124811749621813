import $ from 'jquery'

const $doc = $(document)
const banner = $('.js-banner');
const main = $('main');
const header = $('.js-header');
const mainWrap = main.css('paddingTop');

function openBanner() {
  if (banner.length > 0) {
    banner.addClass('is-loaded');
    const mainPadding = parseInt(mainWrap) + banner.outerHeight();
    header.css('paddingTop', `${banner.outerHeight()}px`);
    main.css('paddingTop', `${mainPadding}px`);
    banner.css('top', 0);
  }
}

function closeBanner(event) {
  event.preventDefault()
  banner.removeClass('is-loaded');
  header.css('paddingTop', 0);
  main.css('paddingTop', `${mainWrap}`);
  banner.css('top', `-${banner.outerHeight()}px`);
}

export default function initBanner(){
  $(document).ready( openBanner )
  $doc.on('click', '.js-banner-close', closeBanner )
}
