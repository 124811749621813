import $ from 'jquery'
const $doc = $(document)

function closeFilterTags() {
  $('#tags-modal').fadeOut(400)
}

function resetSearch() {
  $('.tribe-events-c-search__input').val('');
  $('.tribe-events-c-search__button').click();
}

export default function initEventFilters(){
  $doc.on('click', '.tags-filter--close-modal', closeFilterTags )
  $doc.on('click', '.tribe-common-form-control-checkbox__label', closeFilterTags )
  $doc.on('click', '.tribe-events-c-search__reset-button', resetSearch )
}
