import $ from 'jquery'

function handleAccordions() {
  const allPanels = $('.js-accordion > .icons-text-modal__modal--answer').hide();
  const links = $('.js-accordion > .icons-text-modal__modal--question > a');
  const arrows = $('.js-accordion > .icons-text-modal__modal--question > a > span');

  links.click(function() {
    const answer = $(this).parent().next();
    const arrow = $(this).children();
    allPanels.not(answer).slideUp();
    arrows.not(arrow).removeClass('icons-text-modal__arrow--up');
    links.not($(this)).attr('aria-expanded', 'false');
    answer.slideToggle();
    arrow.toggleClass('icons-text-modal__arrow--up');
    $(this).attr('aria-expanded',
    $(this).attr('aria-expanded') == 'false' ? 'true' : 'false');
    return false;
  });

  // Accordion building block
  const accordionItemHeader = $('.accordion-item__header');

  accordionItemHeader.click(function(){
    $(this).parent().toggleClass('active');
    $(this).next().slideToggle('fast');
  });

}

export default function initAccordions(){
  $(document).ready( handleAccordions )
}
