import $ from 'jquery'
import 'magnific-popup';

function init(){
  $('.js-videos').magnificPopup({
    delegate: 'a',
    type: 'iframe',
    disableOn: 700,
    mainClass: 'mfp-fade',
    midClick: true,
    preloader: false,
    fixedContentPos: false
  });
  $('.js-email-popup').magnificPopup({
    type: 'inline',
    midClick: true
  });
}

function fillEmail() {
  let email = $('.js-newsletter-email').val();
  $("#email-popup input[type='email']").val(email);
}

function fillEmailFooter() {
  let email = $('.js-footer-email').val();
  $("#email-popup input[type='email']").val(email);
}

export default function initPopup(){
  $(document).ready( init );
  $(document).on('click', '.js-email-fill', fillEmail );
  $(document).on('click', '.js-footer-email-fill', fillEmailFooter );
}
