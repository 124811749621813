import $ from 'jquery'
import Cookies from 'js-cookie'

const chatFormBlock = $('.building-block--chat_popup')
const chatForm = $('.building-block--chat_popup')
const formCloseButton = '.building-block--chat_popup .form__close-button'
const chatPopupSeen = Cookies.get('chat_popup_seen') ? JSON.parse(Cookies.get('chat_popup_seen')) : false

function closeChatPopup() {
  Cookies.set('chat_popup_seen', true, { expires: 7 })
  chatForm.remove();
}

export default function initChatPopup(){
  $(document).on('click', formCloseButton, closeChatPopup )
  return chatPopupSeen || chatFormBlock.show()
}
