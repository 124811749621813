import $ from 'jquery';
const headerHeight = $('.header').height();

function scrollBody(offset) {
  $('html, body').animate({
    scrollTop: offset - headerHeight
  }, 500)
}

function scrollToTargetElement(target, event) {
  if (target.startsWith('#')) {
    event.preventDefault();
    scrollBody($(target).offset().top)
  }
}

function adjustScrollPosition() {
  if (window.location.href.indexOf('#') > -1) {
    setTimeout(() => {
      scrollBody(document.documentElement.scrollTop)
    }, 500)
  }
}

export default function initAnchors() {
  $('a').click(function(event) {
    let target = $(this).attr('href');
    scrollToTargetElement(target, event);
  });
  $(document).ready(adjustScrollPosition)
}
