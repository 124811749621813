import $ from 'jquery'
const $doc = $(document)

function openModals(event) {
  event.preventDefault();
  const link = $(event.currentTarget).attr('href');
  $(link).fadeIn(400);
}

function closeModals() {
  const modal = $(this).parent();
  $(modal).fadeOut(400);
}

export default function initOpenModals(){
  $doc.on('click', '.js-open-modal', openModals )
  $doc.on('click', '.js-close-modal', closeModals )
}
