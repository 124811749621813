import $ from 'jquery'
const $doc = $(document)

function playPauseVideo(event) {
  const {currentTarget} = event;
  const video = $(currentTarget).find('video')[0];
  const $button = $(currentTarget).find('svg');
  const method = video.paused ? 'play' : 'pause';

  //$(video).addClass('has-played');

  if (method == 'play') {
    $button.hide()
  } else {
    $button.show()
  }
  video[method]()
}

export default function initPlayPauseVideo(){
  $doc.on('click', '.js-video', playPauseVideo )
}
