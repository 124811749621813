import $ from 'jquery'
const $doc = $(document)

export default function initNavDropdowns(){
  $('.nav--main .main-menu-item').prepend('<div class="nav-bg"></div>');

  $('.nav--main .main-menu-item').hover(
    function() {
      const link = $(this).children('a');
      const color = $(this).attr('data-color');
      const bg = $(this).find('.nav-bg');
      link.addClass('u-color-white');
      bg.addClass(color+'--bg');

      if ($(this).hasClass('menu-item-has-children')) {
        const submenu = $(this).children('.sub-menu');
        const submenuLinks = submenu.find('a');
        $('.sub-menu').not(submenu).slideUp();
        submenu.stop().addClass(color+'--border').slideDown(200);
        submenuLinks.addClass('hover--'+color);
        $(this).attr('aria-expanded', 'true');
        submenu.animate({
          'width': '135px'}, 200
        );
      }
    },
    function() {
      const link = $(this).children();
      const color = $(this).attr('data-color');
      link.removeClass('u-color-white');
      const bg = $(this).find('.nav-bg');
      bg.removeClass(color+'--bg');

      if ($(this).hasClass('menu-item-has-children')) {
        const submenu = $(this).children('.sub-menu');
        $(this).attr('aria-expanded', 'false');
        submenu.hide();
      }
    }
  );

  //second level nav
  let parentMenuItem;
  let activeMenuItem;
  let activeSubmenuItem;
  $('.nav--main .sub-menu-item').mouseenter(
    function() {
      $('.nav--main .sub-menu-item').removeClass('active');
      activeMenuItem = $(this);
      if(activeMenuItem.hasClass('menu-item-has-children')) {
        parentMenuItem = activeMenuItem.parents('.menu-item-depth-0').children('.sub-menu');
        activeSubmenuItem = activeMenuItem.children('.sub-menu');
        activeMenuItem.addClass('active');

        parentMenuItem.animate({
          'min-height': activeSubmenuItem.height()+10+'px'}, 200
        );

        if(activeMenuItem.hasClass('menu-item-depth-1')) {
          parentMenuItem.animate({
            'width': '335px'}, 200
          );
        } else if (activeMenuItem.hasClass('menu-item-depth-2')) {
          parentMenuItem.animate({
            'width': '465px'}, 200
          );
        }
      }
      else {
        if(!parentMenuItem) { return };
        if(activeMenuItem.hasClass('menu-item-depth-1')) {
          parentMenuItem.animate({
            'width': '135px'}, 200
          );
        } else if(activeMenuItem.hasClass('menu-item-depth-2')) {
          parentMenuItem.animate({
            'width': '335px'}, 200
          );
        }
      }
    }
  ).mouseleave(
    function() {
      if(activeMenuItem || !activeSubmenuItem) { return }
      activeMenuItem.removeClass('active');
      activeSubmenuItem.removeClass('active');
    }
  );

  //mobile dropdown
  $('.js-mobile-nav .menu-item-has-children').attr('aria-expanded', 'false');
  $('.js-mobile-nav .menu-item-has-children').children('a').attr('href', "javascript:void(0)");
  $('.js-mobile-nav .menu-item-has-children').click(function() {
    const menu = $(this);
    const submenu = $(this).children('.sub-menu');
    const color = $(this).attr('data-color');
    const links = $(this).find('a');
    submenu.slideToggle(500);
    $(this).toggleClass('submenu-active');
    links.attr('data-color', color);
    if($(this).attr('aria-expanded') == 'false') {
      $(this).attr('aria-expanded', 'true');
      $(this).removeClass('transparent--border').addClass(color+'--border');
    }
    else {
      $(this).attr('aria-expanded', 'false');
      $(this).removeClass(color+'--border');
    }
    $('.sub-menu').not(submenu).slideUp(500, function() {
      $('.menu-item-has-children').not(menu).removeClass('submenu-active').addClass('transparent--border').attr('aria-expanded', 'false');
    });
  });

}
