import $ from 'jquery';

const $doc = $(document);

function openMemberInfo() {
  const arrow = $(this).find('span');
  const info = $(this).next();
  const link = info.find('a');
  const color = $(this).attr('data-color');
  info.slideToggle().toggleClass(color);
  arrow.toggleClass('team__arrow--up');
  $(this).toggleClass(color);
  $(this).prev().toggleClass(color);
  link.toggleClass(color);
  $(this).attr('aria-expanded',
  $(this).attr('aria-expanded') == 'false' ? 'true' : 'false');
}

export default function initTeamInfo(){
  $doc.on('click', '.js-open-member', openMemberInfo )
}
